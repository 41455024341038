import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import {
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagGroupNameLength,
  tagName,
  tagNameLength,
  tagObjectCount,
  tagObjectType,
  tagObjectTypes
} from './analytics-properties'

/**
 * Dialogs
 */

export const tagDeleteDialog = new analytics.Event({
  displayName: 'Tag delete dialog',
  name: 'tag_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const tagGroupDeleteDialog = new analytics.Event({
  displayName: 'Tag group delete dialog',
  name: 'tag_group_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})

/**
 * Menus
 */

export const tagPickerMenu = new analytics.Event({
  displayName: 'Tag picker menu',
  name: 'tag_picker_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId,
  tagObjectCount,
  tagObjectTypes
})
export const tagFilterMenu = new analytics.Event({
  displayName: 'Tag filter menu',
  name: 'tag_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const tagGroupIconMenu = new analytics.Event({
  displayName: 'Tag group icon menu',
  name: 'tag_group_icon_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName
})
export const tagColorMenu = new analytics.Event({
  displayName: 'Tag color menu',
  name: 'tag_color_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagName
})

/**
 * Events
 */

export const tagHide = new analytics.Event({
  displayName: 'Tag hide',
  name: 'tag_hide'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagUnhide = new analytics.Event({
  displayName: 'Tag unhide',
  name: 'tag_unhide'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagFocus = new analytics.Event({
  displayName: 'Tag focus',
  name: 'tag_focus'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagUnfocus = new analytics.Event({
  displayName: 'Tag unfocus',
  name: 'tag_unfocus'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagPin = new analytics.Event({
  displayName: 'Tag pin',
  name: 'tag_pin'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagUnpin = new analytics.Event({
  displayName: 'Tag unpin',
  name: 'tag_unpin'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagGroupPin = new analytics.Event({
  displayName: 'Tag group pin',
  name: 'tag_group_pin'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName
})
export const tagGroupUnpin = new analytics.Event({
  displayName: 'Tag group unpin',
  name: 'tag_group_unpin'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName
})
export const tagObjectAdd = new analytics.Event({
  displayName: 'Tag object add',
  name: 'tag_object_add'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName,
  tagObjectType
})
export const tagObjectRemove = new analytics.Event({
  displayName: 'Tag object remove',
  name: 'tag_object_remove'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName,
  tagObjectType
})
export const tagGroupCreate = new analytics.Event({
  displayName: 'Tag group create',
  name: 'tag_group_create'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName,
  tagGroupNameLength
})
export const tagGroupUpdate = new analytics.Event({
  displayName: 'Tag group update',
  name: 'tag_group_update'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon: {
    ...tagGroupIcon,
    required: false
  },
  tagGroupName: {
    ...tagGroupName,
    required: false
  },
  tagGroupNameLength: {
    ...tagGroupNameLength,
    required: false
  }
})
export const tagGroupDelete = new analytics.Event({
  displayName: 'Tag group delete',
  name: 'tag_group_delete'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName,
  tagGroupNameLength
})
export const tagCreate = new analytics.Event({
  displayName: 'Tag create',
  name: 'tag_create'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName,
  tagNameLength
})
export const tagUpdate = new analytics.Event({
  displayName: 'Tag update',
  name: 'tag_update'
}, {
  landscapeId,
  organizationId,
  tagColor: {
    ...tagColor,
    required: false
  },
  tagGroupIcon,
  tagGroupName,
  tagName: {
    ...tagName,
    required: false
  },
  tagNameLength: {
    ...tagNameLength,
    required: false
  }
})
export const tagDelete = new analytics.Event({
  displayName: 'Tag delete',
  name: 'tag_delete'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName,
  tagNameLength
})
export const tagFilterInclude = new analytics.Event({
  displayName: 'Tag filter include',
  name: 'tag_filter_include'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})
export const tagFilterExclude = new analytics.Event({
  displayName: 'Tag filter exclude',
  name: 'tag_filter_exclude'
}, {
  landscapeId,
  organizationId,
  tagColor,
  tagGroupIcon,
  tagGroupName,
  tagName
})

/**
 * Hidden
 */

export const tagToolbarHidden = new analytics.Event({
  displayName: 'Tag toolbar',
  hidden: true,
  name: 'tag_toolbar'
}, {
  landscapeId,
  organizationId,
  tagGroupIcon,
  tagGroupName
})
export const tagToolbarShowHidden = new analytics.Event({
  displayName: 'Tag toolbar show',
  hidden: true,
  name: 'tag_toolbar_show'
}, {
  landscapeId,
  organizationId
})
export const tagToolbarHideHidden = new analytics.Event({
  displayName: 'Tag toolbar hide',
  hidden: true,
  name: 'tag_toolbar_hide'
}, {
  landscapeId,
  organizationId
})
